import React from "react"
import Fade from "react-reveal/Fade"
// import Swiper core and required modules
import { Autoplay } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

const Platform_Feat_slider = props => {
  return (
    <>
      <div className="">
        <>
          <Fade bottom cascade>
            <Swiper
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                },
                768: {
                  slidesPerView: 3,
                },
                640: {
                  slidesPerView: 2,
                },

                0: {
                  slidesPerView: 1,
                },
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              slidesPerView={4}
              speed={1000}
              spaceBetween={15}
              loop={true}
              modules={[Autoplay]}
            >
              {props.platform_feat_slider_array.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="overflow-hidden cursor-grab">
                      <Fade bottom cascade>
                        <div
                          className={
                            props.mint
                              ? "border border-mint group hover:bg-mint hover:text-white duration-200 p-5 pb-10 group "
                              : "border border-salmon p-5 pb-10 group "
                          }
                        >
                          <div>
                            {props.mint ? (
                              <div className="flex sm:justify-start justify-center">
                                <img
                                  src={item.icon}
                                  width={80}
                                  alt="Softmatter"
                                  className="cursor-pointer my-3 block group-hover:hidden"
                                />
                                <img
                                  src={item.icon_white}
                                  width={80}
                                  alt="Softmatter"
                                  className="cursor-pointer my-3 hidden group-hover:block"
                                />
                              </div>
                            ) : (
                              <div className="flex sm:justify-start justify-center">
                                <img
                                  src={item.icon}
                                  width={80}
                                  alt="Softmatter"
                                  className="cursor-pointer my-3"
                                />
                              </div>
                            )}
                          </div>
                          <div
                            className={
                              props.mint
                                ? "sm:text-left text-center text-xl font-light font-firs-regular border-b border-mint  group-hover:border-white pb-4 max-w-5xl leading-snug mt-4"
                                : "sm:text-left text-center text-xl font-light font-firs-regular border-b border-salmon text-dusk  group-hover: pb-4 max-w-5xl leading-snug mt-4"
                            }
                          >
                            {item.title}
                          </div>
                          <div className="text-base font-light sm:text-left text-center max-w-5xl leading-snug mt-4 h-32  2xl:h-28">
                            {item.desc}
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Fade>
        </>
      </div>
    </>
  )
}

export default Platform_Feat_slider
